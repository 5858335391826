<template>
  <div class="advertising-center" v-show="open">
    <div class="modal-img animated pulse">
      <img
        @dragstart.prevent
        :src="banners[0].img"
        :style="{ cursor: banners[0].linkUrl ? 'pointer' : '' }"
        @click="clickBanner(banners[0].linkUrl)"
      />
      <div class="icon-box" @click="close">
        <i class="el-icon-close"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      banners: [
        {
          linkUrl: "",
          img: "",
        },
      ],
    };
  },
  methods: {
    close() {
      this.open = false;
      localStorage.setItem("advertisingCenter", "close");
    },
    // 点击图片
    clickBanner(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
