<template>
	<div class="home">
		<div class="top-img">
			<div class="top-name">建筑企业经营决策支持平台</div>
			<div class="top-item-box">
				<div class="icon-box" @click="toNavPage('/subscribe/list')">
					<div class="icon-img-ten">
						<img :src="VUE_APP_FILEURL + 'public-mticloud-app-mds/2024/03/29/b9cf302c-bfa6-401d-a982-9c37b5eb781e.png'"
							alt="" />
					</div>
					<div class="icon-name">自选订阅</div>
				</div>
				<div class="icon-box" @click="toNavPage('/project/proposed-project')">
					<div class="icon-img-nine"></div>
					<div class="icon-name">拟建信息</div>
				</div>
				<div class="icon-box" @click="toNavPage('/project/land-transaction')">
					<div class="icon-img-one"></div>
					<div class="icon-name">土地交易</div>
				</div>
				<div class="icon-box" @click="toNavPage('/project/bidding-information')">
					<div class="icon-img-two"></div>
					<div class="icon-name">招标信息</div>
				</div>
				<div class="icon-box" @click="toNavPage('/project/bidding-project-info')">
					<div class="icon-img-three"></div>
					<div class="icon-name">中标信息</div>
				</div>
				<div class="icon-box" @click="toNavPage('/client')">
					<div class="icon-img-five"></div>
					<div class="icon-name">客户信息</div>
				</div>
				<div class="icon-box" @click="toNavPage('/successful/statistical-data')">
					<div class="icon-img-four"></div>
					<div class="icon-name">数据服务</div>
				</div>
				<div class="icon-box" @click="toNavPage('/industry/policy-planning')">
					<div class="icon-img-seven"></div>
					<div class="icon-name">政策规划</div>
				</div>
				<div class="icon-box" @click="toNavPage('/industry/industry-dynamics')">
					<div class="icon-img-six"></div>
					<div class="icon-name">行业动态</div>
				</div>
				<div class="icon-box" @click="toNavPage('/research/new-market')">
					<div class="icon-img-eight"></div>
					<div class="icon-name">市场简报</div>
				</div>
				<div class="icon-box" @click="toNavPage('/viparea')">
					<div class="icon-img-ten">
						<img :src="VUE_APP_FILEURL + 'public-mticloud-app-mds/2024/06/07/54cf7db9-9281-403d-b025-c72bc315d89f.png'"
							alt="" />
					</div>
					<div class="icon-name">会员专区</div>
				</div>
			</div>
		</div>
		<div class="center-box">
			<div class="bell-box bell-center" v-if="bellList.length != 0">
				<div class="bell-icon"></div>
				<el-carousel direction="vertical" arrow="never" indicator-position="none">
					<el-carousel-item v-for="(item, i) in bellList" :key="i">
						<el-tooltip effect="light" :content="item.noticeInfo" placement="bottom-start">
							<!-- <span class="bell-title">{{ item.noticeInfo }}</span> -->
							<span class="bell-title">{{ item.noticeTitle }}</span>
						</el-tooltip>
					</el-carousel-item>
				</el-carousel>
				<div class="more-bell" @click="toMoreBell">查看更多 >></div>
			</div>
			<div class="title mt30">
				<Title :title="'数据概览'" :enTitle="'DATA OVERVIEW'"></Title>
			</div>
			<div class="successful-container">
				<div class="successful-box">
					<div class="title-box">
						<div class="title-left">建筑行业中标统计数据分析</div>
						<div class="title-right" v-show="settingData.indexMapDate">季度：{{ settingData.indexMapDate + "年" +
							this.quarterTextList[settingData.indexMapQuarter] }}</div>
					</div>
					<div class="main-box">
						<div class="left-box">
							<div class="province-name">{{ provinceName }}</div>
							<div class="num-one">
								<div class="item-name">中标项目总数</div>
								<div class="num-box">
									<span class="num">{{ winningNumber }}</span>
									<span>（个）</span>
								</div>
							</div>
							<div class="num-two">
								<div class="item-name">中标项目总金额</div>
								<div class="num-box">
									<span class="num">{{ winningPrice }}</span>
									<span>（亿元）</span>
								</div>
							</div>
						</div>
						<div class="right-box-map">
							<div ref="mapEcharts" class="map-echarts"></div>
							<!-- <div class="update-box">
                                <div class="update" @click="updateMap">
                                <span class="iconfont icon-jiazai_shuaxin"></span>
                                <span>更新</span>
                                </div>
                                <div class="update-time">统计时间：{{ countTime }}</div>
                            </div> -->
						</div>
					</div>
					<!-- <div class="bottom-box">
						<div class="btn-box" @click="entrancePage('/successful/project-info')">
							<div class="icon-bgc"></div>
							<div class="entrance-name">中标信息入口</div>
						</div>
					</div> -->
				</div>
				<div class="successful-box">
					<div class="title-box">
						<div class="title-left">数据概览</div>
						<div style="display: flex">
							<div class="title-right" style="margin-right: 30px" v-show="settingData.indexStatisticsYear">
								季度：{{ settingData.indexStatisticsYear +
									this.quarterTextList[settingData.indexStatisticsQuarter] }}</div>
							<div class="title-right">区域：{{ settingData.indexStatisticsProvince }}</div>
						</div>
					</div>
					<div class="main-box">
						<div class="left-box" style="width: 80%; margin: 0 auto; margin-top: 20px">
							<div>
								<div class="num-one">
									<div class="item-name">中标项目数量</div>
									<div class="num-box">
										<span class="num">{{ overviewData.allNum }}</span>
										<span>（个）</span>
									</div>
								</div>
								<div class="num-two" style="margin-top: 10px">
									<div class="item-name">中标项目总额</div>
									<div class="num-box">
										<span class="num">{{ overviewData.allPrice }}</span>
										<span>（亿元）</span>
									</div>
								</div>
							</div>
							<div>
								<div class="num-three">
									<div class="item-name">招标人总数</div>
									<div class="num-box">
										<span class="num">{{ overviewData.tendereeAllNum }}</span>
										<span>（个）</span>
									</div>
								</div>
								<div class="num-four" style="margin-top: 10px">
									<div class="item-name">中标人总数</div>
									<div class="num-box">
										<span class="num">{{ overviewData.winnerNum }}</span>
										<span>（个）</span>
									</div>
								</div>
							</div>
						</div>
						<div class="right-box">
							<div ref="dataEcharts" class="echarts"></div>
						</div>
					</div>
					<!-- <div class="bottom-box">
						<div class="btn-box" @click="entrancePage('/successful/statistical-data')">
							<div class="icon-bgc"></div>
							<div class="entrance-name">中标统计数据入口</div>
						</div>
					</div> -->
				</div>
			</div>
			<div class="title mt30">
				<Title :title="'最新商机'" :enTitle="'BUSINESS OPPORTUNITIES'"></Title>
			</div>
			<div class="successful-container">
				<div class="successful-box">
					<div class="title-box">
						<div class="title-left">拟建信息</div>
					</div>
					<div class="main-box">
						<div class="empty-box" v-show="!pageBidding || !pageBidding.length">
							<empty :name="'暂无数据'" />
						</div>
						<div class="list-item" v-for="(item, index) in pageBidding" :key="index" @click="toDetail(item, 1)">
							<div class="name-top">
								{{ item.projectName }}
							</div>
							<div class="bottom-row">
								<div class="tag-item">项目节点：{{ item.stageCode || "-" }}</div>
								<div class="tag-item flexstart">地区：{{ item.city || item.province }}</div>
								<div class="tag-item">发布时间：{{ item.publishedDate }}</div>
							</div>
						</div>
						<div class="to-more-left" @click="getMore('/project/proposed-project')">查看更多 ></div>
					</div>
				</div>
				<div class="successful-box">
					<div class="title-box">
						<div class="title-left">招标信息</div>
					</div>
					<div class="main-box">
						<div class="empty-box" v-show="!pageBidding2 || !pageBidding2.length">
							<empty :name="'暂无数据'" />
						</div>
						<div class="list-item" v-for="(item, index) in pageBidding2" :key="index"
							@click="toDetail(item, 2)">
							<div class="name-top">
								<el-tooltip effect="light" :content="item.projectName" placement="bottom-start">
									<div class="text-ellipsis">
										{{ item.projectName }}
									</div>
								</el-tooltip>
							</div>
							<div class="bottom-row">
								<div class="tag-item">招标单位：{{ item.tenderee || "-" }}</div>
								<div class="tag-item flexstart">地区：{{ item.city }}</div>
								<div class="tag-item">发布时间：{{ item.publishedDate }}</div>
							</div>
						</div>
						<div class="to-more-left" @click="getMore('/project/bidding-information')">查看更多 ></div>
					</div>
				</div>
			</div>
			<div class="home-banner" v-show="banners.length != 0">
				<el-carousel :interval="4000">
					<el-carousel-item v-for="(item, i) in banners" :key="i">
						<img @dragstart.prevent :src="item.img" :style="{ cursor: item.linkUrl ? 'pointer' : '' }"
							@click="clickBanner(item.linkUrl)" />
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="title" style="margin-top: 16px">
				<Title :title="'研究报告'" :enTitle="'RESEARCH REPORT'"></Title>
			</div>
			<div class="tab-box">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="市场简报" name="regional">
						<div class="book-box" v-if="activeName == 'regional'">
							<div class="swiper-button-prev"></div>
							<swiper class="swiper-wrapper" ref="regionalSwipers" :options="swiperOptions">
								<swiper-slide class="swiper-slide" v-for="(item, i) in regionalList" :key="i">
									<div class="img-bg" @click="getMoreResearch(item.reportId)">
										<img @dragstart.prevent class="book-img" :src="item.frontCoverImg" alt="" />
										<img @dragstart.prevent class="book-small" :src="item.frontCoverImg" alt="" />
										<el-tooltip effect="light" :content="item.reportTitle" placement="bottom">
											<div class="book-title text-ellipsis-two">
												{{ item.reportTitle }}
											</div>
										</el-tooltip>

										<div class="look">查看</div>
									</div>
								</swiper-slide>
							</swiper>
							<div class="swiper-button-next"></div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="专题报告" name="subdivide">
						<div class="book-box" v-if="activeName == 'subdivide'">
							<div class="swiper-button-prev"></div>
							<swiper class="swiper-wrapper" ref="subdivideSwipers" :options="swiperOptionsSubdivide">
								<swiper-slide class="swiper-slide" v-for="(item, i) in subdivideList" :key="i">
									<div class="img-bg" @click="getMoreResearch(item.reportId)">
										<img @dragstart.prevent class="book-img" :src="item.frontCoverImg" alt="" />
										<img @dragstart.prevent class="book-small" :src="item.frontCoverImg" alt="" />
										<el-tooltip effect="light" :content="item.reportTitle" placement="bottom">
											<div class="book-title text-ellipsis-two">
												{{ item.reportTitle }}
											</div>
										</el-tooltip>

										<div class="look">查看</div>
									</div>
								</swiper-slide>
								<swiper-slide class="swiper-slide">
									<div class="tishi">
										如需获取专业、深度的市场研报，请联系客服反馈需求
									</div>
								</swiper-slide>
							</swiper>
							<div class="swiper-button-next"></div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<div class="to-more" @click="getMoreResearch">查看更多分析报告 ></div>
		</div>
		<login-modal ref="loginModal" :state="state" :bidding="bidding"></login-modal>
		<advertising-center ref="advertisingCenter"></advertising-center>
		<advertising-left ref="advertisingLeft"></advertising-left>
		<bell-modal ref="bellVisible" :list="bellList"></bell-modal>
	</div>
</template>

<script>
import Title from "@/components/Title";
import Empty from "@/components/Empty";
import BellModal from "./bellModal";
import LoginModal from "@/components/loginModal";
import AdvertisingCenter from "@/components/advertisingCenter";
import AdvertisingLeft from "@/components/advertisingLeft";
import { option } from "./chartsConfig";
import province from "./china.json"; //地图包
import * as echarts from "echarts";
export default {
	components: {
		Title,
		BellModal,
		LoginModal,
		AdvertisingCenter,
		AdvertisingLeft,
		Empty,
	},
	data() {
		return {
			VUE_APP_FILEURL: process.env.VUE_APP_FILEURL,
			state: "",
			bidding: "",
			banners: [],
			bellList: [],
			activeName: "regional",
			swiperOptions: {
				autoplay: false,
				nextButton: ".swiper-button-next",
				prevButton: ".swiper-button-prev",
				slidesPerGroup: 5,
				slidesPerView: 5,
				spaceBetween: 10,
				simulateTouch: true, //禁止鼠标拖动切换
			},
			swiperOptionsSubdivide: {
				autoplay: false,
				nextButton: ".swiper-button-next",
				prevButton: ".swiper-button-prev",
				slidesPerGroup: 5,
				slidesPerView: 5,
				spaceBetween: 10,
				simulateTouch: true, //禁止鼠标拖动切换
			},
			regionalList: [],
			subdivideList: [],
			echartsOption: null,
			airData: [],
			provinceName: "",
			winningNumber: 0,
			winningPrice: 0,
			maxWinnerPrice: null, // 最大中标项目总数
			countTime: null,
			overviewData: {
				winnerNum: null,
				allNum: null,
				allPrice: null,
				tendereeAllNum: null,
			}, // 数据概览
			settingData: {},
			queryHide: true,
			canPo: { pageSize: 5, pageNum: 1 },
			pageBidding: [],
			pageBidding2: [],
			quarterTextList: ["", "第一季度", "第二季度", "第三季度", "第四季度"],
		};
	},
	computed: {
		swiper() {
			return this.$refs.regionalSwipers.$swiper;
		},
	},
	mounted() {
		this.getDataAnalysis();
		this.getDataOverviewChart();
		this.getDataOverview();
		let advertisingCenter = localStorage.getItem("advertisingCenter");
		if (!advertisingCenter) {
			this.$api.home
				.getPopupAdvertising({
					code: "1004",
					platform: 1,
				})
				.then(res => {
					if (res.data.length != 0) {
						this.$refs.advertisingCenter.banners = res.data;
						this.$refs.advertisingCenter.open = true;
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		}
		let advertisingLeft = localStorage.getItem("advertisingLeft");
		if (!advertisingLeft) {
			this.$api.home
				.getFloatingAdvertising({
					code: "1004",
					platform: 1,
				})
				.then(res => {
					if (res.data.length != 0) {
						this.$refs.advertisingLeft.open = true;
						this.$refs.advertisingLeft.banners = res.data;
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		}

		// 数据埋点
		this.$api.home.buriedPoint({
			terminal: "60441001",
			consumerId: this.$session.getUsers() ? this.$session.getUsers().consumerId : "",
			positionCode: "604410011001",
		});
		this.getNoticeList();
		this.getBannerList();
		this.getReportList(this.activeName);
		this.getIndexSetting();
		this.getNewBusinessOpportunity();
		if (this.$route.query.state) {
			this.state = this.$route.query.state;
			this.bidding = true;
			this.$refs.loginModal.visible = true;
		}
	},
	methods: {
		// 点击列表查看详情
		toDetail(item, type) {
			let url =
				type == 1
					? this.$router.resolve({
						path: `/industry/proposed-detail?id=${item.proposedProjectId}`,
					})
					: this.$router.resolve({
						path: `/industry/bidding-detail?id=${item.tenderId}`,
					});
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				window.open(url.href, "_blank");
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		getNewBusinessOpportunity() {
			this.$api.industry
				.getProposedProjectPage(this.canPo)
				.then(res => {
					res.rows.forEach(item => {
						item.publishedDate = item.publishedDate.toString().slice(0, 10);
					});
					this.pageBidding = res.rows;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
			this.$api.industry
				.getTenderInfo(this.canPo)
				.then(res => {
					res.rows.forEach(item => {
						item.publishedDate = item.publishedDate.slice(0, 10);
					});
					this.pageBidding2 = res.rows;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 获取系统配置
		getIndexSetting() {
			this.$api.home
				.getIndexSetting()
				.then(res => {
					this.settingData = res.data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 获取用户信息
		getInfo() {
			this.$api.personalCenter
				.getInfo()
				.then(res => {
					let user = res.data;
					user.integrity = +user.integrity;
					if (!user.imgUrl) {
						user.imgUrl = require("../../assets/img/user/user-img.png");
					}
					this.$session.setUsers(user);
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 获取通知列表
		getNoticeList() {
			this.$api.home
				.getNoticeList()
				.then(res => {
					this.bellList = res.data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 获取轮播
		getBannerList() {
			this.$api.home
				.getBannerList({
					code: "1004",
					platform: 1,
				})
				.then(res => {
					this.banners = res.data.slice(0, 5);
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 点击轮播图
		clickBanner(url) {
			if (url) {
				// let urlLaterPart = url.split("#")[1];
				let newUrl = location.origin + "/#" + url;
				window.open(newUrl, "_blank");
			}
		},
		// 获取研究报告
		getReportList(type) {
			this.$api.home
				.getReportList({
					type: type == "regional" ? "10011002" : "10011001",
				})
				.then(res => {
					if (type == "regional") {
						// 市场研究简报
						this.regionalList = res.data;
					} else {
						// 专题报告
						this.subdivideList = res.data;
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 点击快捷入口
		toNavPage(url) {
			if (url) {
				this.$router.push(url);
			}
		},
		getMore(url) {
			this.$router.push(url);
		},
		// 查看更多研究报告
		getMoreResearch() {
			if (this.activeName == "regional") {
				this.$router.push("/research/new-market");
			} else {
				this.$router.push("/research/subdivide-market");
			}
		},
		// 查看报告详情
		toResearchDetail(reportId) {
			let type = 1;
			if (this.activeName == "subdivide") {
				type = 2;
			}

			// 判断用户是否登录
			if (this.$session.getUsers()) {
				// 判断阅读，下载及会员情况
				this.$api.research
					.getReadAndDownload({
						reportId,
					})
					.then(res => {
						let data = res.data;
						if (data.read == "10011001") {
							// 有阅读权限
							let url = this.$router.resolve({
								path: `/research/market-detail?type=${type}&id=${reportId}`,
							});
							window.open(url.href, "_blank");
						} else {
							// 没有阅读权限
							let url = this.$router.resolve({
								path: `/research/market-preview?type=${type}&id=${reportId}`,
							});
							window.open(url.href, "_blank");
						}
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 点击切换报告类型
		handleClick() {
			this.getReportList(this.activeName);
		},
		// 查看更多公告
		toMoreBell() {
			this.$refs.bellVisible.visible = true;
		},
		// 更新地图
		updateMap() {
			this.getDataAnalysis("update");
		},
		// 地图初始化
		initCharts() {
			this.$nextTick(() => {
				const charts = echarts.init(this.$refs["mapEcharts"]);
				const option = {
					// 背景颜色
					backgroundColor: "#fff",
					// 提示浮窗样式
					tooltip: {
						// triggerOn: "click",
						formatter: function (params) {
							if (params.data) {
								return `<div style="font-weight: bold;color: #333;margin-bottom: 10px">${params.name}</div>` + "中标数量:" + params.data.winnerNum + "个" + "<br>" + "中标金额:" + params.data.winnerPrice;
							}
						},
						padding: [10, 20],
						extraCssText: "box-shadow: 0px 0px 29px 0px rgba(10, 89, 218, 0.08);",
						backgroundColor: "rgba(255, 255, 255, 0.9)",
						borderColor: "transparent",
					},
					visualMap: {
						min: 0,
						max: this.maxWinnerPrice,
						left: "right",
						top: "bottom",
						text: ["高", "低"],
						calculable: true,
						inRange: {
							color: ["#CCDBFC", "#3371F4"],
						},
						show: true,
					},
					// 地图配置
					geo: {
						map: "china",
						aspectScale: 0.8, //长宽比,0.75的含义为宽是高的0.75,假如高为100,宽就只有75;0.5的含义就是宽只有高的一半,假如高为100,宽就只有50
						zoom: 1.55, //视觉比例大小,1.2即为原有大小的1.2倍
						roam: false, //是否开启鼠标缩放和平移漫游。默认不开启。可以不用设置,如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。
						top: "26%",
						left: "18%",
						label: {
							// 通常状态下的样式
							normal: {
								show: true,
								textStyle: {
									color: "#000",
								},
							},
							// 鼠标放上去的样式
							emphasis: {
								textStyle: {
									color: "#fff",
								},
							},
						},
						// 地图区域的样式设置
						itemStyle: {
							normal: {
								// 地图边界颜色
								borderColor: "#B0B6C1",
								// 地图区域背景颜色
								areaColor: "#F5F7F8",
							},
							// 鼠标放上去高亮的样式
							emphasis: {
								// 鼠标放上去地图区域背景颜色
								areaColor: "#f3bd44",
								borderWidth: 1,
							},
						},
					},
					series: [
						{
							selectedMode: false, //取消地图区域点击事件
							geoIndex: 0, //将数据和第0个geo配置关联在一起
							type: "map",
							data: this.airData,
						},
					],
				};
				// 地图注册，第一个参数的名字必须和option.geo.map一致
				echarts.registerMap("china", province);
				charts.setOption(option);
				charts.on("click", params => {
					if (params.data) {
						this.provinceName = params.data.province;
						this.winningNumber = params.data.winnerNum;
						this.winningPrice = params.data.price;
						this.countTime = params.data.publishedDate;
					}
				});
				window.addEventListener("resize", () => {
					echarts.resize();
				});
			});
		},
		// 获取地图数据
		getDataAnalysis(update) {
			this.$api.successful
				.getDataAnalysis()
				.then(res => {
					let data = res.data;
					let maxWinnerPrice = +data[0].winnerPrice;
					let xinjiang = {
						name: "新疆",
						province: "新疆",
						price: 0,
						winnerNum: 0,
						winnerPrice: 0,
					};

					data.forEach(item => {
						item.name = item.province.slice(0, item.province.length - 1);
						if (item.province == "西藏自治区") {
							item.name = "西藏";
							item.province = "西藏";
						}
						if (item.province == "广西壮族自治区") {
							item.name = "广西";
							item.province = "广西";
						}
						if (item.province == "内蒙古自治区") {
							item.name = "内蒙古";
							item.province = "内蒙古";
						}
						if (item.province == "宁夏回族自治区") {
							item.name = "宁夏";
							item.province = "宁夏";
						}
						if (item.province == "新疆生产建设兵团") {
							xinjiang.winnerNum += +item.winnerNum;
							xinjiang.winnerPrice += +item.winnerPrice;
						}
						if (item.province == "新疆维吾尔自治区") {
							xinjiang.winnerNum += +item.winnerNum;
							xinjiang.winnerPrice += +item.winnerPrice;
						}
					});
					data.push(xinjiang);
					data.forEach(item => {
						if (+item.winnerPrice > maxWinnerPrice) {
							maxWinnerPrice = +item.winnerPrice;
						}
						item.value = this.$utils.toBillion(item.winnerPrice);
						item.price = this.$utils.toBillion(item.winnerPrice);
						item.winnerPrice = this.$utils.toBillion(item.winnerPrice) + "亿元";
					});
					this.maxWinnerPrice = this.$utils.toBillion(maxWinnerPrice);
					this.provinceName = data[0].province;
					this.winningNumber = data[0].winnerNum;
					this.winningPrice = data[0].price;
					this.countTime = data[0].publishedDate;
					this.airData = data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					this.initCharts();
					if (update) {
						this.$message.success("更新成功");
					}
				});
		},
		// 数据概览
		getDataOverview() {
			this.$api.successful
				.getDataOverview()
				.then(res => {
					let data = res.data;
					data.allPrice = this.$utils.toBillion(data.allPrice);
					this.overviewData = data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 柱状图-折线图数据
		getDataOverviewChart() {
			this.$api.successful
				.getDataOverviewChart()
				.then(res => {
					let data = res.data;
					let priceData = [];
					data.lineData.forEach(item => {
						priceData.push(this.$utils.toBillion(item));
					});
					option.xAxis.data = data.bottomData;
					option.series[0].data = data.histogramData;
					option.series[1].data = priceData;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					this.echartsOption = this.$echarts.initCharts(this.echartsOption, this.$refs["dataEcharts"], option);
				});
		},
		// 点击入口跳转页面
		entrancePage(page) {
			this.$router.push(page);
		},
	},
};
</script>
<style lang="less" scope>
@import "./index.less";
</style>
