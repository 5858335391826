import * as echarts from "echarts";
export const option = {
  backgroundColor: "#fff",
  grid: {
    top: "22%",
    bottom: "20%",
    right: "10%",
    left: "10%"
  },
  tooltip: {
    trigger: "axis",
    backgroundColor: "#fff",
    textStyle: {
      color: "#5c6c7c"
    },
    axisPointer: {
      lineStyle: {
        color: "transparent"
      }
    },
    formatter:
      "<div style='font-weight: bold;color: #333;margin-bottom: 10px'>{b}</div>{a0}: {c0}个<br />{a1}: {c1}亿元<br />",
    padding: [10, 20],
    extraCssText: "box-shadow: 0px 0px 29px 0px rgba(10, 89, 218, 0.08);",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderColor: "transparent"
  },
  legend: {
    data: ["中标项目数量", "中标金额"],
    top: "5%",
    right: "1%",
    textStyle: {
      color: "#333"
    }
  },
  dataZoom: [
    {
      type: "slider",
      bottom: "10",
      show: true,
      xAxisIndex: [0],
      start: 0,
      end: 30,
      height: 10,
      textStyle: {
        color: "#ccd7d7"
      }
    }
  ],

  xAxis: {
    data: [],
    axisLine: {
      show: true,
      lineStyle: {
        color: "#F3F5F7"
      }
    },
    axisTick: {
      show: true
    },
    axisLabel: {
      show: true,
      rotate: 30,
      textStyle: {
        color: "#5e6266"
      }
    }
  },
  yAxis: [
    {
      type: "value",
      name: "（数量）",
      nameTextStyle: {
        color: "#5e6266"
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: true
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#F3F5F7"
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#5e6266"
        }
      }
    },
    {
      type: "value",
      name: "（亿元）",
      nameTextStyle: {
        color: "#5e6266"
      },
      position: "right",
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#F3F5F7"
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#5e6266"
        }
      }
    }
  ],
  series: [
    {
      name: "中标项目数量",
      type: "line",
      smooth: true,
      showSymbol: false,
      symbolSize: 2,
      itemStyle: {
        color: "#4C84FF"
      },
      lineStyle: {
        color: "#4C84FF",
        width: 3
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgba(118,160,255, 0.1)"
          },
          {
            offset: 1,
            color: "rgba(118,160,255, 0)"
          }
        ])
      },
      data: []
    },
    {
      name: "中标金额",
      yAxisIndex: 1,
      type: "bar",
      barWidth: 10,
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgba(252, 47, 79, 0.1)"
            },
            {
              offset: 1,
              color: "rgba(252, 47, 79)"
            }
          ])
        }
      },
      data: []
    }
  ]
};
