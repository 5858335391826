<template>
  <div class="bell-modal">
    <Modal :visible.sync="visible">
      <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">公告</div>
      <div class="bell-list">
        <div class="bell-item" v-for="(item, i) in list" :key="i">
          <span class="type-name">{{ item.noticeTitle }}：</span>
          <span>{{ item.noticeInfo }}</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
