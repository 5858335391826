<template>
  <div class="title-content">
    <div class="top-box">
      <div class="img-left" :class="color ? 'img-left-icon' : ''"></div>
      <div class="title" :class="color ? 'title-color' : ''">{{ title }}</div>
      <div class="img-right" :class="color ? 'img-right-icon' : ''"></div>
    </div>
    <div class="en-title">{{ enTitle }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    color: {
      type: Boolean,
      default: false,
    }, // false 蓝色 true 棕色
    enTitle: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
.title-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .top-box {
    display: flex;
    align-items: center;
    .img-left {
      margin-right: 36px;
      width: 64px;
      height: 16px;
      background: url("../../assets/img/title-icon-left.png") no-repeat;
      background-size: 100% 100%;
    }
    .title {
      font-size: 24px;
      color: #0b1a3d;
    }
    .title-color {
      color: #4D372D;
    }
    .img-right {
      margin-left: 36px;
      width: 76px;
      height: 16px;
      background: url("../../assets/img/title-icon-right.png") no-repeat;
      background-size: 100% 100%;
    }
    .img-left-icon {
      width: 76px;
      background: url("../../assets/img/title-left.png") no-repeat;
    }
    .img-right-icon {
      background: url("../../assets/img/title-right.png") no-repeat;
    }
  }

  .en-title {
    margin-top: -24px;
    font-size: 24px;
    font-weight: bold;
    color: rgba(17, 17, 17, 0.1);
  }
}
</style>
